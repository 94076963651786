<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        {{ competency.name }}
        {{ companyName(competency) }}
      </VToolbarTitle>

      <VSpacer />

      <template v-if="competency.id">
        <VTooltip
          v-if="$route.name !== Names.R_COMPETENCY_MATRIX_COMPETENCY"
          left
        >
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="blue"
              class="mx-1"
              :to="{ name : Names.R_COMPETENCY_MATRIX_COMPETENCY, params : { competencyId : competency.id } }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-eye
              </VIcon>
            </VBtn>
          </template>

          <span>Просмотр</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="orange"
              class="mx-1"
              :to="{ name : Names.R_COMPETENCY_MATRIX_COMPETENCY_EDIT, params : { competencyId : competency.id } }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-edit
              </VIcon>
            </VBtn>
          </template>

          <span>Редактирование</span>
        </VTooltip>
      </template>
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ competency }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_COMPETENCY } from './common';

export default {
  name: 'CompetencyCard',

  inject: ['Names'],

  props: {
    companies: {
      type: Array,
      required: true,
    },

    competency: {
      type: Object,
      default: () => ({ ...DEFAULT_COMPETENCY }),
    },
  },

  methods: {
    companyName(item) {
      const company = this.companies.find((c) => c.id === item.companyId);

      if (company) return `(${company.name})`;

      return '';
    },
  },
};
</script>
